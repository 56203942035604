import { wordMeaningoftheDay } from '../../lib/words'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const WordMeaningModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="ፍንጭ  " isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300" id='wordmeaning'>
      <br/>
      <strong>{wordMeaningoftheDay}</strong>
    </p>
    </BaseModal>
  )
}
