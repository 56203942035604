export const WORDS = [
  'በእምነት',
  'ኢትዮጵያ',
  'ትምህርት',
  'መቅሰፍት',
  'ዐብርሆት',
  'ያደርጋል',
  'ማንኛውም',
  'ተመሳሳይ',
  'አጠቃቀም',
  'ተመለከተ',
  'ጭንቅላት',
  'ተገኝቷል',
  'የመጨረሻ',
  'አታድርግ',
  'እውነተኛ',
  'እርግጠኛ',
  'ይመስላል',
  'አግኝቷል',
  'የተለመደ',
  'አቀማመጥ',
  'ተጠናቀቀ',
  'ተነግሯል',
  'ይገርማል',
  'መስዋዕት',
  'እርግጠኛ',
  'ማስታወሻ',
  'ፊትለፊት',
  'አስተምር',
  'ውቅያኖስ',
  'እውነታው',
  'አስታውስ',
  'ማገልገል',
  'እንቁላል',
  'ምናልባት',
  'ምክንያት',
  'ስነጥበብ',
  'ይለያያል',
  'አጠቃላይ',
  'መከፋፈል',
  'አቀማመጥ',
  'አረጋግጥ',
  'ማስቀመጥ',
  'ሚሊየነር',
  'ትክክለኛ',
  'በስተቀር',
  'መቀላቀል',
  'መሰብሰብ',
  'አስርዮሽ',
  'መካከለኛ',
  'ኢዮቤልዩ',
  'አይሆንም',
  'አስቸጋሪ',
  'አቅርቦት',
  'አመልክት',
  'አስፈላጊ',
  'ማወዳድር',
  'ኦሪጅናል',
  'መሣፈሪያ',
  'ቅጽበታዊ',
  'ኦክስጅን',
  'ቅርንጫፍ',
  'መወያየት',
  'መሰረታዊ',
  'ስምምነት',
  'መወርወር',
  'እንግሊዝ',
  'አውቶቡስ',
  'ፓስፖርት',
  'መጽሐፍት',
  'አስተማሪ',
  'ማቀዝቀዣ',
  'ቴሌቪዥን',
  'የተወሰነ',
  'መነጣጠል',
  'መንሳፈፍ',
  'የዶለዶመ',
  'እየሰከረ',
  'ጋብቻቸው',
  'መሳፍንት',
  'ተዋናይት',
  'አርቲስት',
  'ገጠመኞች',
  'የተለወጠ',
  'መንገደኛ',
  'እንቅርት',
  'እምብርት',
  'መንፈሳዊ',
  'አመሳቀለ',
  'አመላከተ',
  'ማፈግፈግ',
  'ተንኮለኛ',
  'መታጠቅያ',
  'ስግብግብ',
  'መንዛዛት',
  'አስጨነቀ',
  'መጥረጊያ',
  'አመሰገነ',
  'እስራኤል',
  'መታወቂያ',
  'ማዳበሪያ',
  'አስታረቀ',
  'ጉልምስና',
  'ነበልባል',
  'አንድነት',
  'ነጠብጣብ',
  'ብልጭልጭ',
  'ተጠያየቁ',
  'ተከራከረ',
  'ተሰናከለ',
  'ተሸፋፈነ',
  'ተቸነከረ',
  'መወራረድ',
  'አስነዋሪ',
  'አሰናበተ',
  'ሰንሰለት',
  'መድሃኒት',
  'አቀዘቀዘ',
  'አስወገዘ',
  'ማስወገደ',
  'አዝወተረ',
  'አንጋጠጠ',
  'አስታወቀ',
  'አደፈረሰ',
  'አሸበሸበ',
  'አስገደደ',
  'ሃይማኖት',
  'አደባባይ',
  'እስርቤት',
  'መፈንቀያ',
  'ብልሃተኛ',
  'ማስመሪያ',
  'የሚያፈስ',
  'መደገፊያ',
  'ሁለንተና',
  'ሃመልማል',
  'ሃላፊነት',
  'ሃጢያተኛ',
  'ማመንጨት',
  'ሆስፒታል',
  'መለማመጃ',
  'መጋጠሚያ',
  'መለዋወጫ',
  'መልእክት',
  'ላውንደሪ',
  'እንደልቡ',
  'መለስተኛ',
  'ተመልካች',
  'ማመልከቻ',
  'ተመራመረ',
  'አስመረረ',
  'አስመረጠ',
  'ምስጢረኛ',
  'መስከረም',
  'መስፈርት',
  'መሸንገያ',
  'ማምሻውን',
  'መነባንብ',
  'ተመነጠቀ',
  'አመናጨቀ',
  'መንደሪን',
  'መንገጭላ',
  'መንግስት',
  'መከላከያ',
  'ትምክህት',
  'መክተፊያ',
  'መወልውያ',
  'አመዛዘነ',
  'መጠቅለያ',
  'ሚኒስትር',
  'ማህበራዊ',
  'ማሳሰቢያ',
  'ማስታገሻ',
  'ማብራሪያ',
  'የምስራች',
  'መስቀለኛ',
  'መሰንጠቅ',
  'ሰንበሌጥ',
  'ሰንጠረዥ',
  'ሽንኩርት',
  'አስማተኛ',
  'አለማችን',
  'ቆነጃጅት',
  'ከዋክብት',
'ደነገጠች',
'መጨረሻዬ',
'እንጫወት',
'እንቅልፍ',
'ጠርሙሶች',
'አትክልት',
'መስታወት',
'አለመኖር',
'ጉርምስና',
'አፍሪካዊ',
'እንደገና',
'በሕይወት',
'ቀድሞውኑ',
'አስገራሚ',
'አሜሪካዊ',
'መተንተን',
'አፓርታማ',
'ታዳሚዎች',
'እንቅፋት',
'መደብደብ',
'ብስክሌት',
'ማስነሳት',
'መተንፈስ',
'በጥንቃቄ',
'በቅርበት',
'ውስብስብ',
'ኮንሰርት',
'መገንባት',
'መዘግየት',
'መሐንዲስ',
'አምስተኛ',
'እየጨመረ',
'ኦሎምፒክ',
'እርግዝና',
'አቀራረብ',
'ነጸብራቅ',
'ግንኙነት',
'አስወግድ',
'ቴሌስኮፕ',
'መተርጎም',
'ዩኒፎርም',
'የተለያዩ',
'ጉልበተኛ',
];