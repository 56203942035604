import { getStatuses } from '../../lib/statuses'
import { Key } from './Key'
import { useEffect } from 'react'
import { ENTER_TEXT, DELETE_TEXT} from '../../constants/strings'
import { value1, value2, value3, value4, value5, value6, value7} from './Key'
//import KeywriteWeb from "@keywrite/web";
//import { Amharic } from "@keywrite/ethiopic-input-methods";
//import { localeAwareUpperCase } from '../../lib/words'

type Props = {
  onChar: (value: string) => void
  onCharPrint: (value: string) => void
  onDelete: () => any
  onEnter: () => void
  guesses: string[]
  isRevealing?: boolean
}

export const Keyboard = ({
  onChar,
  onCharPrint,
  onDelete,
  onEnter,
  guesses,
  isRevealing,
}: Props) => {
  const charStatuses = getStatuses(guesses)
  
  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    }

    else 
    {
     
       if (value === 'ሁ' || value === 'ሂ'  || value === 'ሃ' || value === 'ሄ' || value === 'ህ' || value === 'ሆ' || value === 'ሉ' || value === 'ሊ' || value === 'ላ' || value === 'ሌ' || value === 'ል' || value === 'ሎ' 
        || value === 'ሑ' || value === 'ሒ' || value === 'ሓ' || value === 'ሔ' || value === 'ሕ' || value === 'ሖ' || value === 'ሙ' || value === 'ሚ' || value === 'ማ' || value === 'ሜ' || value === 'ም' || value === 'ሞ'
        || value === 'ሡ' || value === 'ሢ' || value === 'ሣ' || value === 'ሤ' || value === 'ሥ' || value === 'ሦ' || value === 'ሩ' || value === 'ሪ' || value === 'ራ' || value === 'ሬ' || value === 'ር' || value === 'ሮ' 
        || value === 'ሱ' || value === 'ሲ' || value === 'ሳ'  || value === 'ሴ'  || value === 'ስ' || value === 'ሶ' || value === 'ሹ' || value === 'ሺ' || value === 'ሻ' || value === 'ሼ' || value === 'ሽ' || value === 'ሾ' 
        || value === 'ቁ' || value === 'ቂ' || value === 'ቃ' || value === 'ቄ' || value === 'ቅ' || value === 'ቆ' || value === 'ቡ' || value === 'ቢ' || value === 'ባ' || value === 'ቤ' || value === 'ብ' || value === 'ቦ' 
        || value === 'ቱ' || value === 'ቲ' || value === 'ታ' || value === 'ቴ' || value === 'ት' || value === 'ቶ' || value === 'ቹ' || value === 'ቺ' || value === 'ቻ' || value === 'ቼ' || value === 'ች' || value === 'ቾ' 
        || value === 'ኁ' || value === 'ኂ' || value === 'ኃ' || value === 'ኄ' || value === 'ኅ' || value === 'ኆ' || value === 'ኑ' || value === 'ኒ' || value === 'ና' || value === 'ኔ' || value === 'ን' || value === 'ኖ' 
        || value === 'ኙ' || value === 'ኚ' || value === 'ኛ' || value === 'ኜ' || value === 'ኝ' || value === 'ኞ' || value === 'ኡ' || value === 'ኢ' || value === 'ኣ' || value === 'ኤ' || value === 'እ' || value === 'ኦ'  
        || value === 'ኩ' || value === 'ኪ' || value === 'ካ' || value === 'ኬ' || value === 'ክ' || value === 'ኮ' || value === 'ኹ' || value === 'ኺ' || value === 'ኻ' || value === 'ኼ' || value === 'ኽ' || value === 'ኾ'  
        || value === 'ዉ' || value === 'ዊ' || value === 'ዋ' || value === 'ዌ' || value === 'ው' || value === 'ዎ' || value === 'ዑ' || value === 'ዒ' || value === 'ዓ' || value === 'ዔ' || value === 'ዕ' || value === 'ዖ' 
        || value === 'ዙ' || value === 'ዚ' || value === 'ዛ' || value === 'ዜ' || value === 'ዝ' || value === 'ዞ' || value === 'ዡ' || value === 'ዢ' || value === 'ዣ' || value === 'ዤ' || value === 'ዥ' || value === 'ዦ'  
        || value === 'ዩ' || value === 'ዪ' || value === 'ያ' || value === 'ዬ' || value === 'ይ' || value === 'ዮ'|| value === 'ዱ' || value === 'ዲ' || value === 'ዳ' || value === 'ዴ' || value === 'ድ' || value === 'ዶ'  
        || value === 'ጁ' || value === 'ጂ' || value === 'ጃ' || value === 'ጄ' || value === 'ጅ' || value === 'ጆ' || value === 'ጉ' || value === 'ጊ' || value === 'ጋ' || value === 'ጌ' || value === 'ግ' || value === 'ጎ'  
        || value === 'ጡ' || value === 'ጢ' || value === 'ጣ' || value === 'ጤ' || value === 'ጥ' || value === 'ጦ' || value === 'ጩ' || value === 'ጪ' || value === 'ጫ' || value === 'ጬ' || value === 'ጭ' || value === 'ጮ' 
        || value === 'ጱ'  || value === 'ጲ' || value === 'ጳ' || value === 'ጴ' || value === 'ጵ' || value === 'ጶ'
        || value === 'ጹ' || value === 'ጺ' || value === 'ጻ' || value === 'ጼ' || value === 'ጽ' || value === 'ጾ' || value === 'ፁ' 
        || value === 'ፂ' || value === 'ፃ' || value === 'ፄ' || value === 'ፅ' || value === 'ፆ'
        || value === 'ፉ' || value === 'ፊ' || value === 'ፋ' || value === 'ፌ' || value === 'ፍ' || value === 'ፎ'
        || value === 'ፑ' || value === 'ፒ' || value === 'ፓ' || value === 'ፔ' || value === 'ፕ' || value === 'ፖ'
        || value === 'ቩ' || value === 'ቪ' || value === 'ቫ' || value === 'ቬ' || value === 'ቭ' || value === 'ቮ')
        {
          onCharPrint(value)
          
         }
         else
         {
          onChar(value)
         }
      } 
   }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      }
      else {
        //const key = localeAwareUpperCase(e.key)
        // TODO: check this test if the range works with non-english letters
        if (e.key.length === 1 && e.key >= 'ሀ' && e.key <= 'ቮ') {
          onChar(e.key)
          
        }
      }
     
      
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div>
      <div className="flex justify-center mb-1 " id="addtionalAlphabets" onClick={changeDisplay}>
      {[value1, value2, value3, value4, value5, value6, value7].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
          ))}
      </div>
      <div className="flex justify-center mb-1">
      {['ሀ', 'ለ', 'ሐ', 'መ', 'ሠ', 'ረ', 'ሰ', 'ሸ', 'ቀ', 'በ'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={!isRevealing}
          />

          
        ))}
      </div>
      <div className="flex justify-center mb-1 ">
        {['ተ', 'ቸ', 'ኀ', 'ነ', 'ኘ', 'አ', 'ከ', 'ኸ', 'ወ', 'ዐ'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </div>
      <div className="flex justify-center mb-1 ">
        {['ዘ', 'ዠ', 'የ', 'ደ', 'ጀ', 'ገ', 'ጠ', 'ጨ', 'ጰ', 'ጸ'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
          
        ))}
        
      </div>
      <div className="flex justify-center ">
      <Key width={65.4} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
        {['ፀ', 'ፈ', 'ፐ', 'ቨ'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
         <Key width={65.4} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
      </div>
    </div>
  )

  function changeDisplay() {
    const targetStyle = document.getElementById('addtionalAlphabets');
    if (targetStyle !== null) {
     targetStyle.style.visibility = 'hidden';

  }} 

}

