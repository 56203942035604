export let solutionArrayRow = 1; 

export const amharicAlphabetArrayሀ = ['ሀ', 'ሁ', 'ሂ', 'ሃ', 'ሄ', 'ህ', 'ሆ'];
export const amharicAlphabetArrayለ = ['ለ', 'ሉ', 'ሊ', 'ላ', 'ሌ', 'ል', 'ሎ'];
export const amharicAlphabetArrayሐ = ['ሐ', 'ሑ', 'ሒ', 'ሓ' ,'ሔ' ,'ሕ', 'ሖ'];
export const amharicAlphabetArrayመ = ['መ', 'ሙ', 'ሚ', 'ማ', 'ሜ', 'ም', 'ሞ'];
export const amharicAlphabetArrayሠ = ['ሠ', 'ሡ', 'ሢ', 'ሣ', 'ሤ', 'ሥ', 'ሦ'];
export const amharicAlphabetArrayረ = ['ረ', 'ሩ', 'ሪ', 'ራ' , 'ሬ' , 'ር', 'ሮ'];
export const amharicAlphabetArrayሰ = ['ሰ', 'ሱ', 'ሲ', 'ሳ', 'ሴ', 'ስ', 'ሶ'];
export const amharicAlphabetArrayሸ = ['ሸ', 'ሹ' , 'ሺ' , 'ሻ' , 'ሼ', 'ሽ' , 'ሾ'];
export const amharicAlphabetArrayቀ = ['ቀ', 'ቁ', 'ቂ', 'ቃ' , 'ቄ', 'ቅ', 'ቆ'];
export const amharicAlphabetArrayበ = ['በ', 'ቡ', 'ቢ', 'ባ', 'ቤ', 'ብ', 'ቦ'];
export const amharicAlphabetArrayተ = ['ተ', 'ቱ', 'ቲ', 'ታ', 'ቴ', 'ት', 'ቶ'];
export const amharicAlphabetArrayቸ = ['ቸ', 'ቹ', 'ቺ', 'ቻ', 'ቼ', 'ች', 'ቾ'];
export const amharicAlphabetArrayኀ = ['ኀ', 'ኁ', 'ኂ', 'ኃ', 'ኄ', 'ኅ', 'ኆ'];
export const amharicAlphabetArrayነ = ['ነ', 'ኑ', 'ኒ', 'ና', 'ኔ', 'ን', 'ኖ'];
export const amharicAlphabetArrayኘ = ['ኘ', 'ኙ', 'ኚ', 'ኛ', 'ኜ', 'ኝ', 'ኞ'];
export const amharicAlphabetArrayአ = ['አ', 'ኡ', 'ኢ', 'ኣ', 'ኤ', 'እ', 'ኦ'];
export const amharicAlphabetArrayከ = ['ከ', 'ኩ', 'ኪ', 'ካ', 'ኬ', 'ክ', 'ኮ'];
export const amharicAlphabetArrayኸ = ['ኸ', 'ኹ', 'ኺ', 'ኻ', 'ኼ', 'ኽ', 'ኾ'];
export const amharicAlphabetArrayወ = ['ወ', 'ዉ', 'ዊ', 'ዋ', 'ዌ', 'ው', 'ዎ'];
export const amharicAlphabetArrayዐ = ['ዐ', 'ዑ', 'ዒ', 'ዓ', 'ዔ', 'ዕ', 'ዖ'];
export const amharicAlphabetArrayዘ = ['ዘ', 'ዙ', 'ዚ', 'ዛ', 'ዜ', 'ዝ', 'ዞ'];
export const amharicAlphabetArrayዠ = ['ዠ', 'ዡ', 'ዢ', 'ዣ', 'ዤ', 'ዥ', 'ዦ'];
export const amharicAlphabetArrayየ = ['የ', 'ዩ', 'ዪ', 'ያ', 'ዬ', 'ይ', 'ዮ'];
export const amharicAlphabetArrayደ = ['ደ', 'ዱ', 'ዲ', 'ዳ', 'ዴ', 'ድ', 'ዶ'];
export const amharicAlphabetArrayጀ = ['ጀ', 'ጁ', 'ጂ', 'ጃ', 'ጄ', 'ጅ', 'ጆ'];
export const amharicAlphabetArrayገ = ['ገ', 'ጉ', 'ጊ', 'ጋ', 'ጌ', 'ግ', 'ጎ'];
export const amharicAlphabetArrayጠ = ['ጠ', 'ጡ', 'ጢ', 'ጣ', 'ጤ', 'ጥ', 'ጦ'];
export const amharicAlphabetArrayጨ = ['ጨ', 'ጩ', 'ጪ', 'ጫ', 'ጬ', 'ጭ', 'ጮ'];
export const amharicAlphabetArrayጰ = ['ጰ', 'ጱ', 'ጲ', 'ጳ', 'ጴ', 'ጵ', 'ጶ'];
export const amharicAlphabetArrayጸ = ['ጸ', 'ጹ', 'ጺ', 'ጻ', 'ጼ', 'ጽ', 'ጾ'];
export const amharicAlphabetArrayፀ = ['ፀ', 'ፁ', 'ፂ', 'ፃ', 'ፄ', 'ፅ', 'ፆ'];
export const amharicAlphabetArrayፈ = ['ፈ', 'ፉ', 'ፊ', 'ፋ', 'ፌ', 'ፍ', 'ፎ'];
export const amharicAlphabetArrayፐ = ['ፐ','ፑ', 'ፑ', 'ፓ', 'ፔ', 'ፕ', 'ፖ'];
export const amharicAlphabetArrayቨ = ['ቨ', 'ቩ', 'ቪ', 'ቫ', 'ቬ', 'ቭ', 'ቮ'];

export const amharicAlphabet = [
    ['ሀ', 'ሁ', 'ሂ', 'ሃ', 'ሄ', 'ህ', 'ሆ'],
    ['ለ', 'ሉ', 'ሊ', 'ላ', 'ሌ', 'ል', 'ሎ'],
    ['ሐ', 'ሑ', 'ሒ', 'ሓ' ,'ሔ' ,'ሕ', 'ሖ'],
    ['መ', 'ሙ', 'ሚ', 'ማ', 'ሜ', 'ም', 'ሞ'],
    ['ሠ', 'ሡ', 'ሢ', 'ሣ', 'ሤ', 'ሥ', 'ሦ'],
    ['ረ', 'ሩ', 'ሪ', 'ራ' , 'ሬ' , 'ር', 'ሮ'],
    ['ሰ', 'ሱ', 'ሲ', 'ሳ', 'ሴ', 'ስ', 'ሶ'],
    ['ሸ', 'ሹ' , 'ሺ' , 'ሻ' , 'ሼ', 'ሽ' , 'ሾ'],
    ['ቀ', 'ቁ', 'ቂ', 'ቃ' , 'ቄ', 'ቅ', 'ቆ'],
    ['በ', 'ቡ', 'ቢ', 'ባ', 'ቤ', 'ብ', 'ቦ'],
    ['ተ', 'ቱ', 'ቲ', 'ታ', 'ቴ', 'ት', 'ቶ'],
    ['ቸ', 'ቹ', 'ቺ', 'ቻ', 'ቼ', 'ች', 'ቾ'],
    ['ኀ', 'ኁ', 'ኂ', 'ኃ', 'ኄ', 'ኅ', 'ኆ'],
    ['ነ', 'ኑ', 'ኒ', 'ና', 'ኔ', 'ን', 'ኖ'],
    ['ኘ', 'ኙ', 'ኚ', 'ኛ', 'ኜ', 'ኝ', 'ኞ'],
    ['አ', 'ኡ', 'ኢ', 'ኣ', 'ኤ', 'እ', 'ኦ'],
    ['ከ', 'ኩ', 'ኪ', 'ካ', 'ኬ', 'ክ', 'ኮ'],
    ['ኸ', 'ኹ', 'ኺ', 'ኻ', 'ኼ', 'ኽ', 'ኾ'],
    ['ወ', 'ዉ', 'ዊ', 'ዋ', 'ዌ', 'ው', 'ዎ'],
    ['ዐ', 'ዑ', 'ዒ', 'ዓ', 'ዔ', 'ዕ', 'ዖ'],
    ['ዘ', 'ዙ', 'ዚ', 'ዛ', 'ዜ', 'ዘ', 'ዞ'],
    ['ዠ', 'ዡ', 'ዢ', 'ዣ', 'ዤ', 'ዥ', 'ዦ'],
    ['የ', 'ዩ', 'ዪ', 'ያ', 'ዬ', 'ይ', 'ዮ'],
    ['ደ', 'ዱ', 'ዲ', 'ዳ', 'ዴ', 'ድ', 'ዶ'],
    ['ጀ', 'ጁ', 'ጂ', 'ጃ', 'ጄ', 'ጅ', 'ጆ'],
    ['ገ', 'ጉ', 'ጊ', 'ጋ', 'ጌ', 'ግ', 'ጎ'],
    ['ጠ', 'ጡ', 'ጢ', 'ጣ', 'ጤ', 'ጥ', 'ጦ'],
    ['ጨ', 'ጩ', 'ጪ', 'ጫ', 'ጬ', 'ጭ', 'ጮ'],
    ['ጰ', 'ጱ', 'ጲ', 'ጳ', 'ጴ', 'ጵ', 'ጶ'],
    ['ጸ', 'ጹ', 'ጺ', 'ጻ', 'ጼ', 'ጽ', 'ጾ'],
    ['ፀ', 'ፁ', 'ፂ', 'ፃ', 'ፄ', 'ፅ', 'ፆ'],
    ['ፈ', 'ፉ', 'ፊ', 'ፋ', 'ፌ', 'ፍ', 'ፎ'],
    ['ፕ','ፑ', 'ፑ', 'ፓ', 'ፔ', 'ፕ', 'ፖ'],
    ['ቨ', 'ቩ', 'ቪ', 'ቫ', 'ቬ', 'ቭ', 'ቮ']
];

export function getSolutionGuessedArrayRow(sentLetter: string) {
    if(sentLetter === 'ሀ'|| sentLetter === 'ሁ' || sentLetter === 'ሂ'  || sentLetter === 'ሃ' || sentLetter === 'ሄ' || sentLetter === 'ህ' || sentLetter === 'ሆ')
  {
      solutionArrayRow = 0;
  
  }
  if(sentLetter === 'ለ'|| sentLetter === 'ሉ' || sentLetter === 'ሊ' || sentLetter === 'ላ' || sentLetter === 'ሌ' || sentLetter === 'ል' || sentLetter === 'ሎ')    
  {
      solutionArrayRow = 1;
  
  }
  if(sentLetter === 'ሐ'|| sentLetter === 'ሑ' || sentLetter === 'ሒ' || sentLetter === 'ሓ' || sentLetter === 'ሔ' || sentLetter === 'ሕ' || sentLetter === 'ሖ')
  {
    
      solutionArrayRow = 2;
  
  }
  if(sentLetter === 'መ'|| sentLetter === 'ሙ' || sentLetter === 'ሚ' || sentLetter === 'ማ' || sentLetter === 'ሜ' || sentLetter === 'ም' || sentLetter === 'ሞ')
  {
    
      solutionArrayRow = 3;
  
  }
  if(sentLetter === 'ሠ'|| sentLetter === 'ሡ' || sentLetter === 'ሢ' || sentLetter === 'ሣ' || sentLetter === 'ሤ' || sentLetter === 'ሥ' || sentLetter === 'ሦ') 
  {
      solutionArrayRow = 4;
  
  }
  if(sentLetter === 'ረ'|| sentLetter === 'ሩ' || sentLetter === 'ሪ' || sentLetter === 'ራ' || sentLetter === 'ሬ' || sentLetter === 'ር' || sentLetter === 'ሮ') 
  {
  
      solutionArrayRow = 5;
  
  }
  if(sentLetter === 'ሰ'|| sentLetter === 'ሱ' || sentLetter === 'ሲ' || sentLetter === 'ሳ'  || sentLetter === 'ሴ'  || sentLetter === 'ስ' || sentLetter === 'ሶ')
  {
      solutionArrayRow = 6;
  
  }
  if(sentLetter === 'ሸ'|| sentLetter === 'ሹ' || sentLetter === 'ሺ' || sentLetter === 'ሻ' || sentLetter === 'ሼ' || sentLetter === 'ሽ' || sentLetter === 'ሾ') 
  {
    
      solutionArrayRow = 7;
  
  }
  if(sentLetter === 'ቀ'|| sentLetter === 'ቁ' || sentLetter === 'ቂ' || sentLetter === 'ቃ' || sentLetter === 'ቄ' || sentLetter === 'ቅ' || sentLetter === 'ቆ')
  {
      solutionArrayRow = 8;
  
  }
  if(sentLetter === 'በ'|| sentLetter === 'ቡ' || sentLetter === 'ቢ' || sentLetter === 'ባ' || sentLetter === 'ቤ' || sentLetter === 'ብ' || sentLetter === 'ቦ') 
  {
      solutionArrayRow = 9;
  
  }
  if(sentLetter === 'ተ'|| sentLetter === 'ቱ' || sentLetter === 'ቲ' || sentLetter === 'ታ' || sentLetter === 'ቴ' || sentLetter === 'ት' || sentLetter === 'ቶ')
  {
      solutionArrayRow = 10;
  
  }
  if(sentLetter === 'ቸ'|| sentLetter === 'ቹ' || sentLetter === 'ቺ' || sentLetter === 'ቻ' || sentLetter === 'ቼ' || sentLetter === 'ች' || sentLetter === 'ቾ')
  {
      solutionArrayRow = 11;
  
  }
  if(sentLetter === 'ኀ'|| sentLetter === 'ኁ' || sentLetter === 'ኂ' || sentLetter === 'ኃ' || sentLetter === 'ኄ' || sentLetter === 'ኅ' || sentLetter === 'ኆ') 
  {
      solutionArrayRow = 12;
  
  }
  if(sentLetter === 'ነ'|| sentLetter === 'ኑ' || sentLetter === 'ኒ' || sentLetter === 'ና' || sentLetter === 'ኔ' || sentLetter === 'ን' || sentLetter === 'ኖ') 
  {
      solutionArrayRow = 13;
  
  }
  if(sentLetter === 'ኘ'|| sentLetter === 'ኙ' || sentLetter === 'ኚ' || sentLetter === 'ኛ' || sentLetter === 'ኜ' || sentLetter === 'ኝ' || sentLetter === 'ኞ') 
  {
      solutionArrayRow = 14;
  
  }
  if(sentLetter === 'አ'|| sentLetter === 'ኡ' || sentLetter === 'ኢ' || sentLetter === 'ኣ' || sentLetter === 'ኤ' || sentLetter === 'እ' || sentLetter === 'ኦ')   
  {
      solutionArrayRow = 15;
  
  }
  if(sentLetter === 'ከ'|| sentLetter === 'ኩ' || sentLetter === 'ኪ' || sentLetter === 'ካ' || sentLetter === 'ኬ' || sentLetter === 'ክ' || sentLetter === 'ኮ')  
  {
      solutionArrayRow = 16;
  
  }
  if(sentLetter === 'ኸ'|| sentLetter === 'ኹ' || sentLetter === 'ኺ' || sentLetter === 'ኻ' || sentLetter === 'ኼ' || sentLetter === 'ኽ' || sentLetter === 'ኾ')  
  {
      solutionArrayRow = 17;
  
  } 
  if(sentLetter === 'ወ'|| sentLetter === 'ዉ' || sentLetter === 'ዊ' || sentLetter === 'ዋ' || sentLetter === 'ዌ' || sentLetter === 'ው' || sentLetter === 'ዎ') 
  {
      solutionArrayRow = 18;
  
  }
  if(sentLetter === 'ዐ'|| sentLetter === 'ዑ' || sentLetter === 'ዒ' || sentLetter === 'ዓ' || sentLetter === 'ዔ' || sentLetter === 'ዕ' || sentLetter === 'ዖ') 
  {
      solutionArrayRow = 19;
  
  } 
  if(sentLetter === 'ዘ'|| sentLetter === 'ዙ' || sentLetter === 'ዚ' || sentLetter === 'ዛ' || sentLetter === 'ዜ' || sentLetter === 'ዝ' || sentLetter === 'ዞ')  
  {
      solutionArrayRow = 20;
  
  }
  if(sentLetter === 'ዠ'|| sentLetter === 'ዡ' || sentLetter === 'ዢ' || sentLetter === 'ዣ' || sentLetter === 'ዤ' || sentLetter === 'ዥ' || sentLetter === 'ዦ') 
  {
      solutionArrayRow = 21;
  
  }  
  if(sentLetter === 'የ'|| sentLetter === 'ዩ' || sentLetter === 'ዪ' || sentLetter === 'ያ' || sentLetter === 'ዬ' || sentLetter === 'ይ' || sentLetter === 'ዮ') 
  {
      solutionArrayRow = 22;
  
  }
  if(sentLetter === 'ደ'|| sentLetter === 'ዱ' || sentLetter === 'ዲ' || sentLetter === 'ዳ' || sentLetter === 'ዴ' || sentLetter === 'ድ' || sentLetter === 'ዶ')   
  {
      solutionArrayRow = 23;
  
  }
  if(sentLetter === 'ጀ'|| sentLetter === 'ጁ' || sentLetter === 'ጂ' || sentLetter === 'ጃ' || sentLetter === 'ጄ' || sentLetter === 'ጅ' || sentLetter === 'ጆ') 
  {
      solutionArrayRow = 24;
    
  
  }
  if(sentLetter === 'ገ'|| sentLetter === 'ጉ' || sentLetter === 'ጊ' || sentLetter === 'ጋ' || sentLetter === 'ጌ' || sentLetter === 'ግ' || sentLetter === 'ጎ')   
  {
      solutionArrayRow = 25;
  }
  if(sentLetter === 'ጠ'|| sentLetter === 'ጡ' || sentLetter === 'ጢ' || sentLetter === 'ጣ' || sentLetter === 'ጤ' || sentLetter === 'ጥ' || sentLetter === 'ጦ') 
  {
      solutionArrayRow = 26;
  
  }
  if(sentLetter === 'ጨ'|| sentLetter === 'ጩ' || sentLetter === 'ጪ' || sentLetter === 'ጫ' || sentLetter === 'ጬ' || sentLetter === 'ጭ' || sentLetter === 'ጮ') 
  {
      solutionArrayRow = 27;
  
  } 
  if(sentLetter === 'ጰ'|| sentLetter === 'ጱ'  || sentLetter === 'ጲ' || sentLetter === 'ጳ' || sentLetter === 'ጴ' || sentLetter === 'ጵ' || sentLetter === 'ጶ') 
  {
      solutionArrayRow = 28;
  
  }
  if(sentLetter === 'ጸ'|| sentLetter === 'ጹ' || sentLetter === 'ጺ' || sentLetter === 'ጻ' || sentLetter === 'ጼ' || sentLetter === 'ጽ' || sentLetter === 'ጾ')  
  {
      solutionArrayRow = 29;
  
  }
  if(sentLetter === 'ፀ'|| sentLetter === 'ፁ'  || sentLetter === 'ፂ' || sentLetter === 'ፃ' || sentLetter === 'ፄ' || sentLetter === 'ፅ' || sentLetter === 'ፆ') 
  {
      solutionArrayRow = 30;
  
  }
  if(sentLetter === 'ፈ'|| sentLetter === 'ፉ' || sentLetter === 'ፊ' || sentLetter === 'ፋ' || sentLetter === 'ፌ' || sentLetter === 'ፍ' || sentLetter === 'ፎ') 
  {
      solutionArrayRow = 31;
  
  }
  if(sentLetter === 'ፐ'|| sentLetter === 'ፑ' || sentLetter === 'ፑ' || sentLetter === 'ፓ' || sentLetter === 'ፔ' || sentLetter === 'ፕ' || sentLetter === 'ፖ') 
  {
      solutionArrayRow = 32;
  
  }
  if(sentLetter === 'ቨ'|| sentLetter === 'ቩ' || sentLetter === 'ቪ' || sentLetter === 'ቫ' || sentLetter === 'ቬ' || sentLetter === 'ቭ' || sentLetter === 'ቮ') 
  {
      solutionArrayRow = 33;
  
  }
  
  else {
    solutionArrayRow = 777;
  }
  
  }
