export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['ጀግና!', 'ወንዳታ!', 'ይመችሀ/ሽ!']
export const GAME_COPIED_MESSAGE = 'ጨዋታው ወደ ቅንጥብ ሰሌዳ ተቀድቷል።'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'በቂ ፊደሎች የሉም'
export const WORD_NOT_FOUND_MESSAGE = 'ቃል አልተገኘም።'
export const HARD_MODE_ALERT_MESSAGE =
  'ሃርድ ሞድ መጀመሪያ ላይ ብቻ ነው መንቃት የሚቻለው!'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `የዛሬው ቃል ${solution} ነበር።` 
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `መጠቀም አለበት ${guess} በአቀማመጥ ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `ግምት  ${letter} መያዝ አለበት።`
export const ENTER_TEXT = 'አስገባ ✔️'
export const DELETE_TEXT = 'ሰርዝ 🔙'
//export const HINT_TEXT = 'ፍንጭ 💡'
export const STATISTICS_TITLE = 'ስታትስቲክስ'
export const GUESS_DISTRIBUTION_TEXT = 'የግምት ስርጭት'
export const NEW_WORD_TEXT = 'አዲስ ቃል ከ'
export const SHARE_TEXT = 'አጋራ'
export const TOTAL_TRIES_TEXT = 'አጠቃላይ ጨዋታዎች'
export const SUCCESS_RATE_TEXT = 'የስኬት መጠን'
export const CURRENT_STREAK_TEXT = 'የአሁኑ ሩጫ'
export const BEST_STREAK_TEXT = 'ከፍተኛ ሩጫ'
