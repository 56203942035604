import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="እንዴት እንደሚጫወቱ ማወቅ ይፈልጋሉ?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      <br/>
      <strong>በየቀኑ አንድ(1) ቃል ብቻ ለጨዋታ ይቀርባል።</strong>
      <br/><br/>

      <strong>ቃሉን</strong> በ <strong>6 ሙከራዎች ብቻ</strong> ለመገመት ይሞክሩ።

        <br/><br/>
       እያንዳንዱ ግምት <strong> ትክክለኛ</strong> የ ባለ <strong>5 ፊደል ቃል መሆን አለበት</strong>።
       
      <br/><br/>
      ከእያንዳንዱ ግምት በኋላ፣ ግምታችሁ ከ ዋናው ቃል ጋር ምን ያህል እንደሚቀራረብ ለማሳየት የሳጥኖቹ ቀለም ይቀየራል።

      
      </p>

      <div className="flex justify-center mb-1 mt-4 ">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ኢ"
          status="correct"
        />
        <Cell value="ት" />
        <Cell value="ዮ" />
        <Cell value="ጵ" />
        <Cell value="ያ" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 ">
      ፊደሉ <strong>ኢ</strong> በቃሉ ውስጥ እና <strong>በትክክለኛው</strong>ቦታ ላይ ነው።
      </p>

      <div className="flex justify-center mb-1 mt-4 ">
        <Cell value="በ" />
        <Cell value="እ" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ም"
          status="present"
        />
        <Cell value="ነ" />
        <Cell value="ት" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 ">
      ፊደሉ <strong>ም</strong> በቃሉ ውስጥ ነው, ግን <strong>በተሳሳተ</strong> ቦታ ላይ ነው።
      </p>

      <div className="flex justify-center mb-1 mt-4 ">
        <Cell value="ዐ" />
        <Cell value="ብ" />
        <Cell value="ር" />
        <Cell isRevealing={true} isCompleted={true} value="ሆ" status="absent" />
        <Cell value="ት" />
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300 ">
      ፊደሉ <strong>ሆ</strong> በየትኛውም ቦታ በ ዋናው ቃሉ ውስጥ የለም።</p>

     

      <div className="flex justify-center mb-1 mt-4 ">
        <Cell value="ስ" />
        <Cell value="ም" />
        <Cell value="ም" />
        <Cell isRevealing={true} isCompleted={true} value="ን" status="close" />
        <Cell value="ት" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 ">
      ፊደሉ <strong>ን</strong> በየትኛውም ቦታ በ ዋናው ቃሉ ውስጥ ባይኖርም፤ ከ ነ እስከ ኖ ካሉት ፊደሎች ውስጥ ግን አንዱ አለ።

      <br/><br/>
      <strong>* አዳ-አዲስ ቃል በየቀኑ መጫወት ይችላሉ!</strong>
      </p>

    </BaseModal>
  )
}
