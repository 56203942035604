import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const DeveloperInfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Made By | የተሰራው" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      <br/>
       <strong>በእምነት ዘውድ ከበደ | Beimnet Zewdu Kebede</strong>
       <br/>
       <p className="text-sm text-gray-500 dark:text-gray-300 " id='aboutinfo'>
       <br/>
       ልምድ ያለው የሞባይል - አንድሮይድ እና ድር አፕሊኬሽኖች ገንቢ ባለሙያ 👨🏾‍💻 በጃቫ ላይ የተመሰረቱ አፕሊኬሽኖች ዲዛይን እና ልማት ውስጥ 4️⃣+ ዓመታት ሙያዊ ልምድ ያለው...
       <br/><br/>
       an experienced Mobile - Android and Web applications developer professional 👨🏾‍💻 with 4️⃣+ years of professional experience...
      </p>
      <br/>
       <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm "
            onClick={() => {window.open("https://beimnet-zewdu-website.herokuapp.com");
            }}
          >
          ለተጨማሪ የእኔን ድር ጣቢያ ይጎብኙ | Visit My Website for More 
          </button>
          <br/> <br/>
          <p className='text-sm text-gray-500 dark:text-gray-300'><strong>ሥሪት | Version 1.1</strong></p>
    </p>
    </BaseModal>
  )
}
