export const VALID_GUESSES = [
  'በእምነት',
  'ኢትዮጵያ',
  'ትምህርት',
  'መቅሰፍት',
  'አላውቅም',
  'ማስተዋል',
  'በእምነት',
  'ኢትዮጵያ',
  'ትምህርት',
  'መቅሰፍት',
  'ዐብርሆት',
  'ያደርጋል',
  'ማንኛውም',
  'ተመሳሳይ',
  'አጠቃቀም',
  'ተመልከት',
  'ጭንቅላት',
  'ተገኝቷል',
  'የመጨረሻ',
  'አታድርግ',
  'እውነተኛ',
  'እርግጠኛ',
  'ይመስላል',
  'አግኝቷል',
  'የተለመደ',
  'አቀማመጥ',
  'ተጠናቀቀ',
  'ተነግሯል',
  'ይገርማል',
  'እኩልነት',
  'እርግጠኛ',
  'ማስታወሻ',
  'ፊትለፊት',
  'አስተምር',
  'አረንጓዴ',
  'ውቅያኖስ',
  'እውነታው',
  'አስታውስ',
  'ማገልገል',
  'እንቁላል',
  'ምናልባት',
  'ምክንያት',
  'ስነጥበብ',
  'ይለያያል',
  'አጠቃላይ',
  'መከፋፈል',
  'አቀማመጥ',
  'ክፍልፋይ',
  'አረጋግጥ',
  'ተቀምጧል',
  'ሚሊየነር',
  'ትክክለኛ',
  'በስተቀር',
  'ተቀላቀል',
  'መሰብሰብ',
  'አስርዮሽ',
  'ተሰብሯል',
  'መካከለኛ',
  'አለባበስ',
  'መገናኘት',
  'ተፈጥሯዊ',
  'አይሆንም',
  'አስቸጋሪ',
  'አቅርቦት',
  'አመልክት',
  'አስፈላጊ',
  'አወዳድር',
  'ኦሪጅናል',
  'መሣፈሪያ',
  'ቅጽበታዊ',
  'ይከሰታል',
  'ኦክስጅን',
  'ቅርንጫፍ',
  'መወያየት',
  'መሰረታዊ',
  'ስምምነት',
  'መወርወር',
  'እንግሊዝ',
  'አውቶቢስ',
  'ፓስፖርት',
  'መጽሐፍት',
  'አስተማሪ',
  'ማቀዝቀዣ',
  'ቴሌቪዥን',
  'የተወሰነ',
  'መነጣጠል',
  'መንሳፈፍ',
  'የዶለዶመ',
  'አውቃለው',
  'እየሰከረ',
  'ጋብቻቸው',
  'ከማጋነን',
  'መሳፍንት',
  'ተዋናይት',
  'አርቲስት',
  'ገጠመኞች',
  'አድናቂህ',
  'ይብዛልን',
  'እንገሊዝ',
  'አመለከተ',
  'የተለወጠ',
  'መንገደኛ',
  'እንቅርት',
  'እምብርት',
  'መንፈሳዊ',
  'ደጀሰላም',
  'ተነቃነቀ',
  'መምህራት',
  'መምህራን',
  'አመሳቀለ',
  'አመላከተ',
  'አፈገፈገ',
  'ተንኮለኛ',
  'መታጠቅያ',
  'ስግብግብ',
  'መንዛዛት',
  'አስጨነቀ',
  'መጥረጊያ',
  'አመሰገነ',
  'ማንጠሪያ',
  'እስራኤል',
  'ምዋርተኛ',
  'ታክሲዎች',
  'መታወቂያ',
  'ማዳበሪያ',
  'አገለገለ',
  'አስታረቀ',
  'ጉልምስና',
  'ነበልባል',
  'አንድነት',
  'ነጠብጣብ',
  'ተወዳደረ',
  'ብልጭልጭ',
  'ተጠያየቁ',
  'ተከራከረ',
  'ተሰናከለ',
  'ተሸፋፈነ',
  'ተሰናከለ',
  'ተቸነከረ',
  'ተወራረደ',
  'አስደሰተ',
  'አስነዋሪ',
  'አስወገረ',
  'አሰናበተ',
  'ሰንሰለት',
  'መስፈሪያ',
  'መድሃኒት',
  'አቀዘቀዘ',
  'አስወገዘ',
  'አስወገደ',
  'አዝወተረ',
  'አንጋጠጠ',
  'አስታወቀ',
  'አደፈረሰ',
  'አሸበሸበ',
  'አስገደደ',
  'ሃይማኖት',
  'አደባባይ',
  'እስርቤት',
  'መፈንቀያ',
  'ብልሃተኛ',
  'ማስመሪያ',
  'የሚያፈስ',
  'መደገፊያ',
  'ሙጽሐፍት',
  'ሙጽሐፈት',
  'ሙጽፍሐት',
  'ማበጠሪያ',
  'ፎቶግራፍ',
  'ያገወሰን',
  'ያገወነሰ',
  'ይምወነሰ',
  'ሚኒስትር',
  'ሁለንተና',
'ሃመልማል',
'ሃላፊነት',
'ሃይማኖት',
'ሃጢያተኛ',
'ማመንጨት',
'ሃመልማል',
'ሃገረሰብ',
'ሆስፒታል',
'መለማመጃ',
'መጋጠሚያ',
'ልኩስኩስ',
'መለዋወጫ',
'ልግዝግዝ',
'መልእክት',
'ላውንደሪ',
'አይጠራም',
'እንደልቡ',
'ተመላላሽ',
'ተመላለሰ',
'መለስተኛ',
'ተመልካች',
'ማመልከቻ',
'ተመራመረ',
'መረማመጃ',
'አስመረረ',
'አስመረጠ',
'ተመስገን',
'ምስጢረኛ',
'መስከረም',
'መስፈርት',
'መሸንገያ',
'ማምሻውን',
'መቆስቆሻ',
'መታወቂያ',
'መነባንብ',
'ተመነጠቀ',
'አመናጨቀ',
'መንደሪን',
'መንገደኛ',
'መንገጭላ',
'መንደርቶ',
'መንግስት',
'መከላከያ',
'ምክንያት',
'ትምክህት',
'መካከላኛ',
'መክተፊያ',
'መወልውያ',
'መዘባበቻ',
'መስዋእት',
'መውደቂያ',
'አመዛዘነ',
'መደንቆያ',
'መጠቅለያ',
'ተመጣጣኝ',
'ሙልጭልጭ',
'ሙጭርጭር',
'ሚኒስቴር',
'ማህበራዊ',
'ማሳሰቢያ',
'ማስለቀሻ',
'ማስታወሻ',
'ማስታገሻ',
'ማብራሪያ',
'ማቀባበያ',
'ማቀናነሻ',
'ማቁላሊያ',
'ማቅረቢያ',
'ማወራረጃ',
'ማደንዥዣ',
'ማጣበቂያ',
'የምስራች',
'ተሞላቀቀ',
'ተሞካከሩ',
'እርግጠኛ',
'ሪፖርተር',
'መሳለሚያ',
'ማስመሪያ',
'መስቀለኛ',
'መስበቂያ',
'ሰንሰለት',
'ተሰነዘረ',
'ተሰነጠቀ',
'ሰንበሌጥ',
'ሰንጠረዥ',
'ተሰገሰገ',
'ተንሳፈፈ',
'ሳርቅጠሉ',
'ሳይንሳዊ',
'ስነውበት',
'ስነጥበብ',
'ስነፈለክ',
'ስክቢሪቶ',
'ስንክሳር',
'ተሸረሸረ',
'አሸረጠች',
'ተሸጋሸገ',
'ሽፍንፍን',
'ሽንኩርት',
'አበራየው',
'አስማተኛ',
'አለማችን',
'አላማችን',
'ደነገገዉ',
'በረንዳው',
'የተሸከሙ',
'በፀሃይማ',
'ጠረጴዛው',
'ሀመልማል',
'መሠረተች',
'ቆነጃጅት',
'ማከማቸት',
'ሽፋሽፍት',
'አፈታፈት',
'ከዋክብት',
'መሠረትን',
'መሠረትን',
'ክዋክብት',
'ማከማቸት',
'በወንጌል',
'መታሰቢያ',
'መታቀፈያ',
'መታወቂያ',
'መታጠቂያ',
'መታጠቢያ',
'ሀቀኝነት',
'ተበታተነ',
'መደማመር',
'ቃልኪዳን',
'አብዮታዊ',
'ተቸገርን',
'ተመቻቸን',
'አስፈለገ',
'ደስአለኝ',
'መሳለቂያ',
'አሳደገኝ',
'አሳደገው',
'ብልጽግና',
'አሰቸገረ',
'አሜሪካን',
'አሸበረቀ',
'አለመለመ',
'እንናገር',
'ጎልብታል',
'ገለበጠው',
'ተግባራዊ',
'አረመኔው',
'አውራምባ',
'አውራዶሮ',
'መደንገጥ',
'ደነገጠች',
'አሰገደች',
'መጨረሻዬ',
'እንጫወት',
'መሠረታዊ',
'እንቅልፍ',
'ጠርሙሶች',
'መነጋገር',
'ጎረመሰች',
'መነኩሴው',
'መበላለጥ',
'እባካችሁ',
'መቆጣጠር',
'መሰናዶው',
'መከረኛው',
'መነቃቃት',
]
