import { solution, unicodeSplit } from './words'
import { amharicAlphabetArrayሀ, amharicAlphabetArrayለ, amharicAlphabetArrayሐ, amharicAlphabetArrayመ, amharicAlphabetArrayሠ, 
  amharicAlphabetArrayረ, amharicAlphabetArrayሰ, amharicAlphabetArrayሸ, amharicAlphabetArrayቀ, amharicAlphabetArrayበ,
   amharicAlphabetArrayቨ, amharicAlphabetArrayተ, amharicAlphabetArrayቸ, amharicAlphabetArrayኀ, amharicAlphabetArrayነ, 
   amharicAlphabetArrayኘ, amharicAlphabetArrayአ, amharicAlphabetArrayከ, amharicAlphabetArrayኸ, amharicAlphabetArrayወ, 
   amharicAlphabetArrayዐ, amharicAlphabetArrayዘ, amharicAlphabetArrayዠ, amharicAlphabetArrayየ, amharicAlphabetArrayደ,
    amharicAlphabetArrayጀ, amharicAlphabetArrayገ, amharicAlphabetArrayጠ, amharicAlphabetArrayጨ, amharicAlphabetArrayጰ, 
    amharicAlphabetArrayጸ, amharicAlphabetArrayፀ, amharicAlphabetArrayፈ, amharicAlphabetArrayፐ } from '../constants/alphabet'

export type CharStatus = 'absent' | 'present' | 'correct' | 'close'

export const getStatuses = (
  guesses: string[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}
  const splitSolution = unicodeSplit(solution)
  

  guesses.forEach((word) => {
    unicodeSplit(word).forEach((letter, i) => {

      //getSolutionGuessedArrayRow(splitSolution[i]);

       if(!splitSolution.includes(letter) && isGuessAndSolutionOnTheSameRow(letter, splitSolution[i]))
        {
          
          return (charObj[letter] = 'close')
        }
        
      if (!splitSolution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      if (letter === splitSolution[i]) {
        //make status correct
        return (charObj[letter] = 'correct')
      }

      if (charObj[letter] !== 'correct') {
        //make status present
        return (charObj[letter] = 'present')
      }

      
  
    })
  })

  return charObj
}

export const getGuessStatuses = (guess: string): CharStatus[] => {
  const splitSolution = unicodeSplit(solution)
  const splitGuess = unicodeSplit(guess)

  const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: CharStatus[] = Array.from(Array(guess.length))
  

  // handle all correct cases first
  splitGuess.forEach((letter, i) => { 
   if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
   // console.log("The Guessed letter is " + letter + " but the solutionArrayRow is: " + solutionArrayRow)
   // console.log("The Solution letter is " + splitSolution[i] + " but the guessedArrayRow is: " + guessedArrayRow)

    if (statuses[i]) return

    if(!splitSolution.includes(letter) && isGuessAndSolutionOnTheSameRow(letter, splitSolution[i]))
    {
      statuses[i] = 'close'
      return
    }

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
    
  })

  return statuses


}

function isGuessAndSolutionOnTheSameRow(guessedLetter: string, solutionLetter: string)
{
  if(amharicAlphabetArrayሀ.includes(guessedLetter) && amharicAlphabetArrayሀ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayለ.includes(guessedLetter) && amharicAlphabetArrayለ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayሐ.includes(guessedLetter) && amharicAlphabetArrayሐ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayመ.includes(guessedLetter) && amharicAlphabetArrayመ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayሠ.includes(guessedLetter) && amharicAlphabetArrayሠ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayረ.includes(guessedLetter) && amharicAlphabetArrayረ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayሰ.includes(guessedLetter) && amharicAlphabetArrayሰ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayሸ.includes(guessedLetter) && amharicAlphabetArrayሸ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayቀ.includes(guessedLetter) && amharicAlphabetArrayቀ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayበ.includes(guessedLetter) && amharicAlphabetArrayበ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayተ.includes(guessedLetter) && amharicAlphabetArrayተ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayቸ.includes(guessedLetter) && amharicAlphabetArrayቸ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayኀ.includes(guessedLetter) && amharicAlphabetArrayኀ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayነ.includes(guessedLetter) && amharicAlphabetArrayነ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayኘ.includes(guessedLetter) && amharicAlphabetArrayኘ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayአ.includes(guessedLetter) && amharicAlphabetArrayአ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayከ.includes(guessedLetter) && amharicAlphabetArrayከ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayኸ.includes(guessedLetter) && amharicAlphabetArrayኸ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayወ.includes(guessedLetter) && amharicAlphabetArrayወ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayዐ.includes(guessedLetter) && amharicAlphabetArrayዐ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayዘ.includes(guessedLetter) && amharicAlphabetArrayዘ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayዠ.includes(guessedLetter) && amharicAlphabetArrayዠ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayየ.includes(guessedLetter) && amharicAlphabetArrayየ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayደ.includes(guessedLetter) && amharicAlphabetArrayደ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayጀ.includes(guessedLetter) && amharicAlphabetArrayጀ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayገ.includes(guessedLetter) && amharicAlphabetArrayገ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayጠ.includes(guessedLetter) && amharicAlphabetArrayጠ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayጨ.includes(guessedLetter) && amharicAlphabetArrayጨ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayጰ.includes(guessedLetter) && amharicAlphabetArrayጰ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayጸ.includes(guessedLetter) && amharicAlphabetArrayጸ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayፀ.includes(guessedLetter) && amharicAlphabetArrayፀ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayፈ.includes(guessedLetter) && amharicAlphabetArrayፈ.includes(solutionLetter))
  {
    return true;
  }
   if(amharicAlphabetArrayፐ.includes(guessedLetter) && amharicAlphabetArrayፐ.includes(solutionLetter))
  {
    return true;
  }
  if(amharicAlphabetArrayቨ.includes(guessedLetter) && amharicAlphabetArrayቨ.includes(solutionLetter))
  {
    return true;
  }
  
  else
  {
  return false;
  }
}