import { ReactNode } from 'react'
import classnames from 'classnames'
import { CharStatus } from '../../lib/statuses'
import { MAX_WORD_LENGTH, REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { useLongPress } from "react-use";


type Props = {
  children?: ReactNode
  value: string
  width?: number
  status?: CharStatus
  onClick: (value: string) => void
  isRevealing?: boolean
}


let value1: string = 'ሀ', value2: string = 'ሁ', value3: string = 'ሂ', 
value4: string = 'ሃ', value5: string = 'ሄ', value6: string = 'ህ', 
value7: string = 'ሆ'

let onLongPressFunction: any;

export {value1, value2, value3, value4, value5, value6, value7}
export {onLongPressFunction}


export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
  isRevealing,
}: Props) => {
  const keyDelayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH
  const isHighContrast = getStoredIsHighContrastMode()
  
   const onLongPress = () => {
    if(value === 'ሀ' )
    {
      value1 = 'ሀ'
      value2 = 'ሁ'
      value3 = 'ሂ'
      value4 = 'ሃ'
      value5 = 'ሄ'
      value6 = 'ህ'
      value7 = 'ሆ'
    }
  
    else if(value === 'ለ' )
    {
      value1 = 'ለ'
      value2 = 'ሉ'
      value3 = 'ሊ'
      value4 = 'ላ'
      value5 = 'ሌ'
      value6 = 'ል'
      value7 = 'ሎ'
    }
  
    else if(value === 'ሐ' )
    {
      value1 = 'ሐ'
      value2 = 'ሑ'
      value3 = 'ሒ'
      value4 = 'ሓ'
      value5 = 'ሔ'
      value6 = 'ሕ'
      value7 = 'ሖ'
    }
    
    else if(value === 'መ' )
    {
      value1 = 'መ'
      value2 = 'ሙ'
      value3 = 'ሚ'
      value4 = 'ማ'
      value5 = 'ሜ'
      value6 = 'ም'
      value7 = 'ሞ'
    }
    
    else if(value === 'ሠ' )
    {
      value1 = 'ሠ'
      value2 = 'ሡ'
      value3 = 'ሢ'
      value4 = 'ሣ'
      value5 = 'ሤ'
      value6 = 'ሥ'
      value7 = 'ሦ'
    }
    
    else if(value === 'ረ' )
    {
      value1 = 'ረ'
      value2 = 'ሩ'
      value3 = 'ሪ'
      value4 = 'ራ'
      value5 = 'ሬ'
      value6 = 'ር'
      value7 = 'ሮ'
    }
    
    else if(value === 'ሰ' )
    {
      value1 = 'ሰ'
      value2 = 'ሱ'
      value3 = 'ሲ'
      value4 = 'ሳ'
      value5 = 'ሴ'
      value6 = 'ስ'
      value7 = 'ሶ'
    }
    
    else if(value === 'ሸ' )
    {
      value1 = 'ሸ'
      value2 = 'ሹ'
      value3 = 'ሺ'
      value4 = 'ሻ'
      value5 = 'ሼ'
      value6 = 'ሽ'
      value7 = 'ሾ'
    }
    
    else if(value === 'ቀ' )
    {
      value1 = 'ቀ'
      value2 = 'ቁ'
      value3 = 'ቂ'
      value4 = 'ቃ'
      value5 = 'ቄ'
      value6 = 'ቅ'
      value7 = 'ቆ'
    }
    
    else if(value === 'በ' )
    {
      value1 = 'በ'
      value2 = 'ቡ'
      value3 = 'ቢ'
      value4 = 'ባ'
      value5 = 'ቤ'
      value6 = 'ብ'
      value7 = 'ቦ'
    }
    
    else if(value === 'ተ' )
    {
      value1 = 'ተ'
      value2 = 'ቱ'
      value3 = 'ቲ'
      value4 = 'ታ'
      value5 = 'ቴ'
      value6 = 'ት'
      value7 = 'ቶ'
    }
    
    else if(value === 'ቸ' )
    {
      value1 = 'ቸ'
      value2 = 'ቹ'
      value3 = 'ቺ'
      value4 = 'ቻ'
      value5 = 'ቼ'
      value6 = 'ች'
      value7 = 'ቾ'
    }
    
    else if(value === 'ኀ' )
    {
      value1 = 'ኀ'
      value2 = 'ኁ'
      value3 = 'ኂ'
      value4 = 'ኃ'
      value5 = 'ኄ'
      value6 = 'ኅ'
      value7 = 'ኆ'
    }
    
    else if(value === 'ነ' )
    {
      value1 = 'ነ'
      value2 = 'ኑ'
      value3 = 'ኒ'
      value4 = 'ና'
      value5 = 'ኔ'
      value6 = 'ን'
      value7 = 'ኖ'
    }
    
    else if(value === 'ኘ' )
    {
      value1 = 'ኘ'
      value2 = 'ኙ'
      value3 = 'ኚ'
      value4 = 'ኛ'
      value5 = 'ኜ'
      value6 = 'ኝ'
      value7 = 'ኞ'
    }
    
    else if(value === 'አ' )
    {
      value1 = 'አ'
      value2 = 'ኡ'
      value3 = 'ኢ'
      value4 = 'ኣ'
      value5 = 'ኤ'
      value6 = 'እ'
      value7 = 'ኦ'
    }
    
    else if(value === 'ከ' )
    {
      value1 = 'ከ'
      value2 = 'ኩ'
      value3 = 'ኪ'
      value4 = 'ካ'
      value5 = 'ኬ'
      value6 = 'ክ'
      value7 = 'ኮ'
    }
    
    else if(value === 'ኸ' )
    {
      value1 = 'ኸ'
      value2 = 'ኹ'
      value3 = 'ኺ'
      value4 = 'ኻ'
      value5 = 'ኼ'
      value6 = 'ኽ'
      value7 = 'ኾ'
    }
    
    else if(value === 'ወ' )
    {
      value1 = 'ወ'
      value2 = 'ዉ'
      value3 = 'ዊ'
      value4 = 'ዋ'
      value5 = 'ዌ'
      value6 = 'ው'
      value7 = 'ዎ'
    }
    
    else if(value === 'ዐ' )
    {
      value1 = 'ዐ'
      value2 = 'ዑ'
      value3 = 'ዒ'
      value4 = 'ዓ'
      value5 = 'ዔ'
      value6 = 'ዕ'
      value7 = 'ዖ'
    }
    
    else if(value === 'ዘ' )
    {
      value1 = 'ዘ'
      value2 = 'ዙ'
      value3 = 'ዚ'
      value4 = 'ዛ'
      value5 = 'ዜ'
      value6 = 'ዝ'
      value7 = 'ዞ'
    }
    
    else if(value === 'ዠ' )
    {
      value1 = 'ዠ'
      value2 = 'ዡ'
      value3 = 'ዢ'
      value4 = 'ዣ'
      value5 = 'ዤ'
      value6 = 'ዥ'
      value7 = 'ዦ'
    }
    
    else if(value === 'የ' )
    {
      value1 = 'የ'
      value2 = 'ዩ'
      value3 = 'ዪ'
      value4 = 'ያ'
      value5 = 'ዬ'
      value6 = 'ይ'
      value7 = 'ዮ'
    }
    
    else if(value === 'ደ' )
    {
      value1 = 'ደ'
      value2 = 'ዱ'
      value3 = 'ዲ'
      value4 = 'ዳ'
      value5 = 'ዴ'
      value6 = 'ድ'
      value7 = 'ዶ'
    }
    
    else if(value === 'ጀ' )
    {
      value1 = 'ጀ'
      value2 = 'ጁ'
      value3 = 'ጂ'
      value4 = 'ጃ'
      value5 = 'ጄ'
      value6 = 'ጅ'
      value7 = 'ጆ'
    }
    
    else if(value === 'ገ' )
    {
      value1 = 'ገ'
      value2 = 'ጉ'
      value3 = 'ጊ'
      value4 = 'ጋ'
      value5 = 'ጌ'
      value6 = 'ግ'
      value7 = 'ጎ'
    }
    
    else if(value === 'ጠ' )
    { 
      value1 = 'ጠ'
      value2 = 'ጡ'
      value3 = 'ጢ'
      value4 = 'ጣ'
      value5 = 'ጤ'
      value6 = 'ጥ'
      value7 = 'ጦ'
    }
    
    else if(value === 'ጨ' )
    {
      value1 = 'ጨ'
      value2 = 'ጩ'
      value3 = 'ጪ'
      value4 = 'ጫ'
      value5 = 'ጬ'
      value6 = 'ጭ'
      value7 = 'ጮ'
    }
    
    else  if(value === 'ጰ' )
    {
      value1 = 'ጰ'
      value2 = 'ጱ'
      value3 = 'ጲ'
      value4 = 'ጳ'
      value5 = 'ጴ'
      value6 = 'ጵ'
      value7 = 'ጶ'
    }
    
    else if(value === 'ጸ' )
    {
      value1 = 'ጸ'
      value2 = 'ጹ'
      value3 = 'ጺ'
      value4 = 'ጻ'
      value5 = 'ጼ'
      value6 = 'ጽ'
      value7 = 'ጾ'
    }
    
    else if(value === 'ፀ' )
    {
      value1 = 'ፀ'
      value2 = 'ፁ'
      value3 = 'ፂ'
      value4 = 'ፃ'
      value5 = 'ፄ'
      value6 = 'ፅ'
      value7 = 'ፆ'
    }
    
    else if(value === 'ፈ' )
    {
      value1 = 'ፈ'
      value2 = 'ፉ'
      value3 = 'ፊ'
      value4 = 'ፋ'
      value5 = 'ፌ'
      value6 = 'ፍ'
      value7 = 'ፎ'
    }
    
    else if(value === 'ፐ' )
    {
      value1 = 'ፐ'
      value2 = 'ፑ'
      value3 = 'ፒ'
      value4 = 'ፓ'
      value5 = 'ፔ'
      value6 = 'ፕ'
      value7 = 'ፖ'
    }
    else if(value === 'ቨ' )
    {
      value1 = 'ቨ'
      value2 = 'ቩ'
      value3 = 'ቪ'
      value4 = 'ቫ'
      value5 = 'ቬ'
      value6 = 'ቭ'
      value7 = 'ቮ'
    }
    else
    {
      
    }

  
  }; 
  const defaultOptions = {
    isPreventDefault: false,
    delay: 5
  };
  const longPressEvent = useLongPress(onLongPress, defaultOptions);
  onLongPressFunction = {longPressEvent}
 

  const classes = classnames(
    'flex items-center justify-center rounded mx-0.5 text-xs font-italic-bold cursor-pointer select-none dark:text-white',
    {
      'transition ease-in-out': isRevealing,
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status,
      'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        status === 'correct' && isHighContrast,
      'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white':
        status === 'present' && isHighContrast,
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        status === 'correct' && !isHighContrast,
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present' && !isHighContrast,
      'bg-orange-500 hover:bg-yellow-600 active:bg-orange-700 text-white':
        status === 'close' && !isHighContrast,
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
    height: '58px',
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

 

  function changeDisplay() {
  const targetStyle = document.getElementById('addtionalAlphabets');
    if (targetStyle !== null) {
     targetStyle.style.visibility = 'visible';

  }
  /*const dStyle = Array.from(document.getElementsByTagName('addtionalAlphabets') as HTMLCollectionOf<HTMLElement>);
    dStyle.forEach((element) => {
      element.style.display = 'block';

  }) */
  }
  return (
   <button style={styles} className={classes} onClick={handleClick} {...longPressEvent} {...changeDisplay()}>
      {children || value}
    </button>
  )

  
}
 
//onClick={() => { handleOpen(); handleChange() }}
