import {
  //InformationCircleIcon,
  ChartBarIcon,
  CodeIcon,
 // ExternalLinkIcon,
// DocumentIcon,
 //HomeIcon,
 InformationCircleIcon,
 LightBulbIcon,
 // CogIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  solution,
  findFirstUnusedReveal,
  unicodeLength,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import { WordMeaningModal } from './components/modals/WordMeaningModal'
import { DeveloperInfoModal } from './components/modals/DeveloperInfoModal'
import database from './firebase';
import 'firebase/database';
import OneSignal from 'react-onesignal';
//import {onLongPressFunction} from './components/keyboard/Key'

let guessedArrayRow = 0;
function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isWordMeaningModalOpen, setIsWordMeaningModalOpen] = useState(false)
  const [isDeveloperInfoModalOpen, setIsDeveloperInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  
  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  let currentValue = ''
  

  
  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
    
  }, [])

  useEffect(() => {
    OneSignal.init({
      appId: "6216cbb9-ff1e-4581-b413-7f3f807daafb"
    });
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
      changeVisibilityDisplay('moonIcon', 'none', 'sunIcon', 'inline')
    } else {
      document.documentElement.classList.remove('dark')
      changeVisibilityDisplay('moonIcon', 'inline', 'sunIcon', 'none')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })

      //add firebase implementation
      PushCountToFirebase()
    
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      currentValue = value
      getGuessedArrayRow(currentValue)
      setCurrentGuess(`${currentGuess}${value}`)
   }
  }

  const onCharPrint = (value: string) => {
    currentValue = value
    getGuessedArrayRow(currentValue)
    setCurrentGuess(new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('') + value)
 }


  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  

  const onEnter = () => {   
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
  
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
      
    }

    PushValueToFirebase(currentGuess)


    //

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * MAX_WORD_LENGTH + 1,
        })
      }
    }

  }

  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-20">
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {GAME_TITLE}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <MoonIcon
          id='moonIcon'
          display = "inline"
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => changeDarkLightMode()}
        />
         <SunIcon
          id='sunIcon'
          display = "none"
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => changeDarkLightMode()}
        />
        <LightBulbIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsWordMeaningModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CodeIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsDeveloperInfoModalOpen(true)}
        />
        {/*
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        />
  */}
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      <Keyboard
        onChar={onChar}
        onCharPrint= {onCharPrint}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
       // stringToBeReplaced={currentGuess}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode} numberOfGuessesMade={0}      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />

      <WordMeaningModal
      isOpen={isWordMeaningModalOpen}
      handleClose={() => setIsWordMeaningModalOpen(false)}
      />

      <DeveloperInfoModal
      isOpen={isDeveloperInfoModalOpen}
      handleClose={() => setIsDeveloperInfoModalOpen(false)}
      />

      <AlertContainer />
      
    </div>
    
  )

  function changeDarkLightMode()
  {
    if(isDarkMode){
      setIsDarkMode(false)
      changeVisibilityDisplay('sunIcon', 'none', 'moonIcon', 'inline')
    }
    else if(!isDarkMode){
      setIsDarkMode(true)
      changeVisibilityDisplay('moonIcon', 'none', 'sunIcon', 'inline')
    }
    
  }

  function changeVisibilityDisplay(originalID: string, originalVisibility: string, targetID: string, targetVisibility: string) {
    const targetStyle1 = document.getElementById(originalID);
    const targetStyle2 = document.getElementById(targetID);
    if (targetStyle1 !== null && targetStyle2 !== null) {
     targetStyle1.style.display = originalVisibility;
     targetStyle2.style.display = targetVisibility;

  }} 

  

}


export {guessedArrayRow} 
export default App

function getGuessedArrayRow(currentValue: string) {
  if(currentValue === 'ሀ'|| currentValue === 'ሁ' || currentValue === 'ሂ'  || currentValue === 'ሃ' || currentValue === 'ሄ' || currentValue === 'ህ' || currentValue === 'ሆ')
  {
    guessedArrayRow = 0;
  
  }
  if(currentValue === 'ለ'|| currentValue === 'ሉ' || currentValue === 'ሊ' || currentValue === 'ላ' || currentValue === 'ሌ' || currentValue === 'ል' || currentValue === 'ሎ')    
  {
    guessedArrayRow = 1;
  
  }
  if(currentValue === 'ሐ'|| currentValue === 'ሑ' || currentValue === 'ሒ' || currentValue === 'ሓ' || currentValue === 'ሔ' || currentValue === 'ሕ' || currentValue === 'ሖ')
  {
    guessedArrayRow = 2;
  
  }
  if(currentValue === 'መ'|| currentValue === 'ሙ' || currentValue === 'ሚ' || currentValue === 'ማ' || currentValue === 'ሜ' || currentValue === 'ም' || currentValue === 'ሞ')
  {
    guessedArrayRow = 3;
  
  }
  if(currentValue === 'ሠ'|| currentValue === 'ሡ' || currentValue === 'ሢ' || currentValue === 'ሣ' || currentValue === 'ሤ' || currentValue === 'ሥ' || currentValue === 'ሦ') 
  {
    guessedArrayRow = 4;
  
  }
  if(currentValue === 'ረ'|| currentValue === 'ሩ' || currentValue === 'ሪ' || currentValue === 'ራ' || currentValue === 'ሬ' || currentValue === 'ር' || currentValue === 'ሮ') 
  {
    guessedArrayRow = 5;
  
  }
  if(currentValue === 'ሰ'|| currentValue === 'ሱ' || currentValue === 'ሲ' || currentValue === 'ሳ'  || currentValue === 'ሴ'  || currentValue === 'ስ' || currentValue === 'ሶ')
  {
    guessedArrayRow = 6;
  
  }
  if(currentValue === 'ሸ'|| currentValue === 'ሹ' || currentValue === 'ሺ' || currentValue === 'ሻ' || currentValue === 'ሼ' || currentValue === 'ሽ' || currentValue === 'ሾ') 
  {
    guessedArrayRow = 7;
  
  }
  if(currentValue === 'ቀ'|| currentValue === 'ቁ' || currentValue === 'ቂ' || currentValue === 'ቃ' || currentValue === 'ቄ' || currentValue === 'ቅ' || currentValue === 'ቆ')
  {
    guessedArrayRow = 8;
  
  }
  if(currentValue === 'በ'|| currentValue === 'ቡ' || currentValue === 'ቢ' || currentValue === 'ባ' || currentValue === 'ቤ' || currentValue === 'ብ' || currentValue === 'ቦ') 
  {
    guessedArrayRow = 9;
  
  }
  if(currentValue === 'ተ'|| currentValue === 'ቱ' || currentValue === 'ቲ' || currentValue === 'ታ' || currentValue === 'ቴ' || currentValue === 'ት' || currentValue === 'ቶ')
  {
    guessedArrayRow = 10;
  
  }
  if(currentValue === 'ቸ'|| currentValue === 'ቹ' || currentValue === 'ቺ' || currentValue === 'ቻ' || currentValue === 'ቼ' || currentValue === 'ች' || currentValue === 'ቾ')
  {
    guessedArrayRow = 11;
  
  }
  if(currentValue === 'ኀ'|| currentValue === 'ኁ' || currentValue === 'ኂ' || currentValue === 'ኃ' || currentValue === 'ኄ' || currentValue === 'ኅ' || currentValue === 'ኆ') 
  {
    guessedArrayRow = 12;
  
  }
  if(currentValue === 'ነ'|| currentValue === 'ኑ' || currentValue === 'ኒ' || currentValue === 'ና' || currentValue === 'ኔ' || currentValue === 'ን' || currentValue === 'ኖ') 
  {
    guessedArrayRow = 13;
  
  }
  if(currentValue === 'ኘ'|| currentValue === 'ኙ' || currentValue === 'ኚ' || currentValue === 'ኛ' || currentValue === 'ኜ' || currentValue === 'ኝ' || currentValue === 'ኞ') 
  {
    guessedArrayRow = 14;
  
  }
  if(currentValue === 'አ'|| currentValue === 'ኡ' || currentValue === 'ኢ' || currentValue === 'ኣ' || currentValue === 'ኤ' || currentValue === 'እ' || currentValue === 'ኦ')   
  {
    guessedArrayRow = 15;
  
  }
  if(currentValue === 'ከ'|| currentValue === 'ኩ' || currentValue === 'ኪ' || currentValue === 'ካ' || currentValue === 'ኬ' || currentValue === 'ክ' || currentValue === 'ኮ')  
  {
    guessedArrayRow = 16;
  
  }
  if(currentValue === 'ኸ'|| currentValue === 'ኹ' || currentValue === 'ኺ' || currentValue === 'ኻ' || currentValue === 'ኼ' || currentValue === 'ኽ' || currentValue === 'ኾ')  
  {
    guessedArrayRow = 17;
  
  } 
  if(currentValue === 'ወ'|| currentValue === 'ዉ' || currentValue === 'ዊ' || currentValue === 'ዋ' || currentValue === 'ዌ' || currentValue === 'ው' || currentValue === 'ዎ') 
  {
    guessedArrayRow = 18;
  
  }
  if(currentValue === 'ዐ'|| currentValue === 'ዑ' || currentValue === 'ዒ' || currentValue === 'ዓ' || currentValue === 'ዔ' || currentValue === 'ዕ' || currentValue === 'ዖ') 
  {
    guessedArrayRow = 19;
  
  } 
  if(currentValue === 'ዘ'|| currentValue === 'ዙ' || currentValue === 'ዚ' || currentValue === 'ዛ' || currentValue === 'ዜ' || currentValue === 'ዝ' || currentValue === 'ዞ')  
  {
    guessedArrayRow = 20;
  
  }
  if(currentValue === 'ዠ'|| currentValue === 'ዡ' || currentValue === 'ዢ' || currentValue === 'ዣ' || currentValue === 'ዤ' || currentValue === 'ዥ' || currentValue === 'ዦ') 
  {
    guessedArrayRow = 21;
  
  }  
  if(currentValue === 'የ'|| currentValue === 'ዩ' || currentValue === 'ዪ' || currentValue === 'ያ' || currentValue === 'ዬ' || currentValue === 'ይ' || currentValue === 'ዮ') 
  {
    guessedArrayRow = 22;
  
  }
  if(currentValue === 'ደ'|| currentValue === 'ዱ' || currentValue === 'ዲ' || currentValue === 'ዳ' || currentValue === 'ዴ' || currentValue === 'ድ' || currentValue === 'ዶ')   
  {
    guessedArrayRow = 23;
  
  }
  if(currentValue === 'ጀ'|| currentValue === 'ጁ' || currentValue === 'ጂ' || currentValue === 'ጃ' || currentValue === 'ጄ' || currentValue === 'ጅ' || currentValue === 'ጆ') 
  {
    guessedArrayRow = 24;
  
  }
  if(currentValue === 'ገ'|| currentValue === 'ጉ' || currentValue === 'ጊ' || currentValue === 'ጋ' || currentValue === 'ጌ' || currentValue === 'ግ' || currentValue === 'ጎ')   
  {
    guessedArrayRow = 25;
  
  }
  if(currentValue === 'ጠ'|| currentValue === 'ጡ' || currentValue === 'ጢ' || currentValue === 'ጣ' || currentValue === 'ጤ' || currentValue === 'ጥ' || currentValue === 'ጦ') 
  {
    guessedArrayRow = 26;
  
  }
  if(currentValue === 'ጨ'|| currentValue === 'ጩ' || currentValue === 'ጪ' || currentValue === 'ጫ' || currentValue === 'ጬ' || currentValue === 'ጭ' || currentValue === 'ጮ') 
  {
    guessedArrayRow = 27;
  
  } 
  if(currentValue === 'ጰ'|| currentValue === 'ጱ'  || currentValue === 'ጲ' || currentValue === 'ጳ' || currentValue === 'ጴ' || currentValue === 'ጵ' || currentValue === 'ጶ') 
  {
    guessedArrayRow = 28;
  
  }
  if(currentValue === 'ጸ'|| currentValue === 'ጹ' || currentValue === 'ጺ' || currentValue === 'ጻ' || currentValue === 'ጼ' || currentValue === 'ጽ' || currentValue === 'ጾ')  
  {
    guessedArrayRow = 29;
  
  }
  if(currentValue === 'ፀ'|| currentValue === 'ፁ' || currentValue === 'ፂ' || currentValue === 'ፃ' || currentValue === 'ፄ' || currentValue === 'ፅ' || currentValue === 'ፆ') 
  {
    guessedArrayRow = 30;
  
  }
  if(currentValue === 'ፈ'|| currentValue === 'ፉ' || currentValue === 'ፊ' || currentValue === 'ፋ' || currentValue === 'ፌ' || currentValue === 'ፍ' || currentValue === 'ፎ') 
  {
    guessedArrayRow = 31;
  
  }
  if(currentValue === 'ፐ'|| currentValue === 'ፑ' || currentValue === 'ፑ' || currentValue === 'ፓ' || currentValue === 'ፔ' || currentValue === 'ፕ' || currentValue === 'ፖ') 
  {
    guessedArrayRow = 32;
  
  }
  if(currentValue === 'ቨ'|| currentValue === 'ቩ' || currentValue === 'ቪ' || currentValue === 'ቫ' || currentValue === 'ቬ' || currentValue === 'ቭ' || currentValue === 'ቮ') 
  {
    guessedArrayRow = 33;
  
  }
}

const PushValueToFirebase = (currentValuefromButton: string) => {
  database.ref("guessed_words " + getCurrentDateTime()).set({
    guessed_word: currentValuefromButton,
  }).catch(alert);
}

const PushCountToFirebase = () => {
  database.ref("finished? " + getCurrentDateTime()).set({
    finished: "yes",
  }).catch(alert);
}


function getCurrentDateTime()
{
  return Date().toLocaleString()
}


