export const WORDS_MEANING = [
  'በእምነት',
  'ኢትዮጵያ',
  'ትምህርት',
  'መቅሰፍት',
  'ዐብርሆት',
  'ያደርጋል',
  'ማንኛውም',
  'ተመሳሳይ',
  'አጠቃቀም',
  'ተመለከተ',
  'ጭንቅላት',
  'ተገኝቷል',
  'የመጨረሻ',
  'አታድርግ',
  'እውነተኛ',
  'እርግጠኛ',
  'ይመስላል',
  'አግኝቷል',
  'የተለመደ',
  'አቀማመጥ',
  'ተጠናቀቀ',
  'ተነግሯል',
  'ይገርማል',
  'መስዋዕት',
  'እርግጠኛ',
  'ማስታወሻ',
  'ፊትለፊት',
  '🏫🧑🏾‍🏫👩🏾‍🏫',
  'ወንዝ ፣ ባህር ፣ በጣም ትልቅ ስፋት ወይም መጠን.',
  'የሚታወቅ ወይም እውነት ሆኖ የተረጋገጠ ነገር።',
  '🤔💭🔙',
  'ለ (ሌላ ሰው ወይም ድርጅት) ተግባራትን ወይም አገልግሎቶችን መስጠት።',
  '🐔🥚',
  'ም _ ል _ ት - ሊሆንም ላይሆንም ይችላል።',
  'ስ _ _ በ _ \n ለአንድ ድርጊት ወይም ክስተት ማብራሪያ ወይም ማረጋገጫ',
  'የሰው ልጅ የፈጠራ ክህሎት እና ምናብ አገላለጽ ወይም አተገባበር',
  'ይ _ _ ያ ል',
  'ሁሉንም ወይም ብዙ ሰዎችን ወይም ነገሮችን የሚነካ ወይም የሚመለከት; _ _ ቃ _ ይ.',
  '➗➗',
  '1. አንድ ሰው ወይም የሆነ ነገር የሚገኝበት ወይም የተቀመጠበት ቦታ። \n 2. በ አ ጀምሮ በ ት የሚያልቅ',
  'እውነትን ወይም ሕልውናውን በማስረጃ ወይም በክርክር ማሳየት።',
  '💾💾',
  '1️⃣💵💵💵',
  '✅✔️',
  'በ_ተ_ር',
  'ማገናኛ; መገናኘት፣ መደባለቅ፣ ...',
  'መ_ _ _ ብ ፣ 👨‍👩‍👧‍👧👨‍👩‍👧‍👦',
  'አ _ _ ዮ _ ፣ የነጥብ ሌላ ቃል',
  '⬆️ ⏺️ ⬇️',
  '20 አመት',
  '❌, የአይቻልም ሌላ ቃል',
  'ለማከናወን፣ ለመቋቋም ወይም ለመረዳት ብዙ ጥረት ወይም ችሎታ የሚፈልግ።',
  'ለአንድ ሰው (የሚፈለግ ወይም የሚፈለግ ነገር) እንዲገኝ ማድረግ; ማቅረብ።',
  'ለስራ እጩ በመደበኛነት እራስን ማስቀመጥ።',
  'ትልቅ ጠቀሜታ ወይም ዋጋ ያለው።',
  'በመካከላቸው ያለውን ተመሳሳይነት ወይም አለመመሳሰል ይገምቱ፣ ይለኩ ወይም ያስተውሉ።',
  'መጀመሪያ ወይም ቀደምት',
  'ተሳፋሪዎች እንዲወርዱ ወይም እንዲወርዱ ባቡሮች በመደበኛነት የሚቆሙበት በባቡር መስመር ላይ ያለ ቦታ።',
  'ቅ _  _  _ ዊ ፣ ወዲያውኑ ይመጣል።',
  'ሕይወትን የሚደግፍ የአየር ክፍል።',
  'ከግንዱ ውስጥ የሚበቅል የዛፍ ክፍል.',
  '🗣️',
  'የአንድ ርዕሰ ጉዳይ ወይም ችሎታ አስፈላጊ እውነታዎች ወይም መርሆዎች።',
  '🤝🏾🤝🏾',
  '🤾🏾‍♀️🤾🏾‍♀️',
  'ይህ ቋንቋ ፣ በዓለም ዙሪያ በብዙ ዓይነቶች በሰፊው ጥቅም ላይ ይውላል።',
  '🚏🚌',
  'በመንግስት የተሰጠ ኦፊሴላዊ ሰነድ የያዛውን ማንነት እና ዜግነት የሚያረጋግጥ እና በሱ ጥበቃ ስር ወደ ውጭ እና ወደ ውጭ ሀገራት እንዲጓዙ የሚያስችላቸው።',
  '📚📚',
  '🏫👨🏾‍🏫👩🏾‍🏫',
  'ለማቀዝቀዝ ወይም ለማቆየት ምግብን ወይም መጠጥን ወደ ቀዝቃዛ የማስገባት ሂደት።',
  '📺',
  'በመጠን ወይም በመጠን የተገደበ; ጥቂት፣ ትንሽ ወይም አጭር።',
  'መ _ _ _ ል ፣ 💔',
  '🚤🛶🏊🏾',
  'ሹል ጫፍ ወይም ነጥብ የሌለው',
  '🍺🍻🥴',
  '🤵🏾👰🏾',
  'መሳፍንት',
  'ሳያት ደምሴ ፣ ሰላም ተስፋዬ ፣ ሃና ታሪክ ...',
  'ቴዲ አፍሮ ፣ ጂጂ ፣ ጥላሁን ገሰሰ ...',
  'ያልተለመደ እና አስደሳች ወይም ደፋር ተሞክሮ።',
  'የ _ _ _ ጠ ፣ መቀየር ወይም መስተካከል',
  'የሚጓዝ ሰው',
  '_ _ ቅ _ ት ፣ ስለ አንድ ነገር የመጨነቅ ፣ የመረበሽ ስሜት',
  'እ _ ብ _ _ ፣ የስው አካል',
  'ከቁሳዊ ወይም አካላዊ ነገሮች በተቃራኒ የሰውን መንፈስ ወይም ነፍስ ማዛመድ ወይም መንካት',
  'ሥርዓታማ ያልሆነ እና ለመረዳት የሚያስቸግር',
  'ትችትን በቀጥታ እና በማያሻማ መንገድ መግለጽ',
  'በላቀ ኃይላቸው ወይም ከሽንፈት በኋላ ከጠላት ኃይሎች መራቅ።',
  'በማታለል ወይም በመሸሽ ዓላማን ለማሳካት ችሎታ ያለው ሰው።',
  'ፈረስ ወይም ሌላ ረቂቅ እንስሳ በጋሪው ላይ የሚጣበቁበት ማሰሪያና ማሰሪያ፣ ማረሻ፣',
  'ለአንድ ነገር ጠንካራ እና ራስ ወዳድነት በተለይም ሀብትን፣ ስልጣንን ወይም ምግብን መፈለግ።',
  'ከቦታ ወደ ቦታ ያለ ዓላማ መጓዝ',
  'ብስጭት ወይም ችግር የሚፈጥር ሰው ወይም ነገር።',
  '🧹🧹',
  'ስጦታን፣ አገልግሎትን፣ ወይም አድናቆትን ስንሰጥ',
  '🔯🕎',
  'የአንድን ሰው ማንነት በተለይም ኦፊሴላዊ ወረቀቶችን የማረጋገጥ ዘዴ',
  '🧑🏾‍🌾♻️🌱',
  'ወዳጃዊ ግንኙነት ወደነበረበት መመለስ',
  'የብስለት ሁኔታ፣ እውነታ ወይም ጊዜ።',
  'ነ _ _ _ _ ፣ 🔥🔥',
  '✊🏾🤝🏾',
  'ትናንሽ ክቦች',
  '🌟🎆',
  'መልስ ወይም አንዳንድ መረጃ ለማግኘት አንድ ነገር ይናገሩ',
  'አንድን ሃሳብ፣ ድርጊት ወይም ንድፈ ሃሳብ የሚደግፉ ምክንያቶችን ይስጡ ወይም ማስረጃን ይጥቀሱ፣ በተለይም ሌሎች የአንድን ሰው አመለካከት እንዲጋሩ ለማሳመን።',
  'ጉዞ ማድረግ ወይም ለጊዜው ሚዛን ማጣት; ሊወድቅ ነው ማለት ይቻላል።',
  'የሆነ ነገርን ከፊት ወይም ከፊት (አንድ ነገር) በተለይም መከላከል ወይም መደበቅ',
  'በምስማር ተጣብቆ ወይም የተገነባ.',
  'እንደ ውድድር ወይም ጨዋታ ያለ ያልተጠበቀ ክስተት ውጤት ላይ በመመስረት የገንዘብ ወይም ዋጋ ያለው ነገር ላይ ማስያዝ',
  'አሳፋሪ',
  '👋🏾👋🏾',
  '🔗⛓️',
  '💊',
  '🥶❄️🍧',
  'አስወገዘ',
  'ማጥፋት',
  'ብዙ ጊዜ ተመላለሰ',
  'ወደ ላይ አየ',
  'ስለ አንድ እውነታ ፣ ክስተት ፣ ወይም ዓላማ መደበኛ የህዝብ መግለጫ ስጠ።',
  'ማበላሸት',
  'ቤተክርስትያን ውስጥ ከመዝሙር ጋር የሚደረግ እንቅስቃሴ',
  'በግዳጅ ወይም በአካላዊ ኃይል የተገኘ ወይም የተጫነ።',
  '⛪🕌⛩️',
  'መስቀል _____ ፣ ዳያስፖራ _____',
  'ሰዎች በፈጸሙት ወንጀል ወይም ፍርድ በመጠባበቅ ላይ እያሉ በህጋዊ መንገድ እንደ ቅጣት የሚያዙበት ህንፃ።',
  'አንድን ነገር ከቦታው ወይም ከቦታው የማንቀሳቀስ ተግባር',
  'የሆነ ነገር በማድረግ ወይም በማሳካት የተካነ; ጎበዝ',
  '📏',
  'ቀዳዳ ያለው ነገር',
  'እርዳታ መስጠት',
  'ሁለንተና',
  '______ አባተ፣ የእርምህን አውጣ ዘፋኝ',
  'ለአንድ ነገር ተጠያቂ የመሆን ሁኔታ ወይም እውነታ።',
  '😈💀🤬',
  'ማምረት ወይም መፍጠር.',
  '🏥',
  '⛹🏾‍♀️🤸🏾‍♂️🎾',
  'የማሽን ክፍሎችን ለማገናኘት መሳሪያ.',
  'የጠፋውን ወይም የተበላሸውን የማሽን ክፍል ለመተካት የተባዛ ክፍል።',
  '✉️📨📬',
  '👔👕👖🚰',
  'እንፈለገ',
  'ከባድ ያልሆነ',
  'በትዕይንት ፣ በጨዋታ ወይም በሌላ ክስተት ላይ የሚመለከት ሰው።',
  'ለስራ ቦታ እንዲታይ ወይም የሆነ ነገር እንዲሰራ ወይም እንዲፈቀድለት፣ ለባለስልጣን፣ ተቋም ወይም ድርጅት እንዲቀርብ መደበኛ ጥያቄ።',
  'ስልታዊ በሆነ መንገድ መመርመር.',
  '😠😡',
  'ከሁለት ወይም ከዚያ በላይ አማራጮች ምርጥ ወይም በጣም ተገቢ እንደሆነ ይምረጡ።',
  '🔐🤫',
  'ሆ ብለን መጣን ሆ ብለን \n ጌቶች  አሉ ብለን \n ሆ ብለን መጣን ሆ ብለን \n እሜቴ አሉ ብለን',
  'የሆነ ነገር የሚዳኝበት ወይም የሚወሰንበት መርህ',
  'ለመፍታት በክርክር ውስጥ ጣልቃ መግባት; የግልግል ዳኝነት',
  '🌆🌃',
  'መ _ ባ ን _',
  'በድንገት በመያዝ መሰረቅ',
  'ስለ አንድ ነገር ቅሬታ ወይም ተቃውሞ።',
  '🍊 መ _ _ _ን',
  'የታችኛው ተንቀሳቃሽ የመንጋጋ አጥንት',
  '🧑🏾‍⚖️📜🏛️',
  '🛡️🪖',
  'የራስን ክብር ንቃተ ህሊና.',
  '🔪🥩',
  '🧹💧',
  '⚖️',
  '🎁🌯',
  'የመንግስት መምሪያ ኃላፊ.',
  'ከህብረተሰቡ ወይም ከድርጅቱ ጋር የተያያዘ.',
  'ለማስታወስ አጋዥ ሆነው የተጻፉ ነጥቦች ወይም ሀሳቦች አጭር መዝገብ።',
  'ከጭንቀት እና ከጭንቀት ነጻ የመሆን ሁኔታ.',
  'የሆነ ነገር ግልጽ የሚያደርግ መግለጫ',
  'ጥሩ ዜና',
  '➕ 🛣️',
  'አንድ ነገር ሳይሰበር...',
  'የነቶሎ ቶሎ ቤት ግድግዳ',
  'በስልት የሚታየው የእውነታዎች ወይም የቁጥሮች ስብስብ፣ በተለይም በአምዶች ውስጥ።',
  '🧅😭',
  '🪄',
  '🌍',
  '👩🏾‍🦰👩🏾‍🦱👩🏾‍🦳👱🏾‍♀️',
  '✨💫⭐',
  '😨👩🏾‍🦱',
  '🔚',
  '▶️🏀',
  '💤💤💤',
  '🥛🍷🍸🥃🥂',
  '🍅🥕🥒🫑🍆',
  '🪞',
  '🧔🏾‍♂️👩🏾‍🦱💀',
'🧔🏾‍♂️ እድሜ 10-24',
'🌍 🧔🏾‍♂️👩🏾‍🦱',
'🔁🔁',
'በ 🧬',
'በፊት ወይም አሁን ወይም በጥያቄ ውስጥ ያለው ጊዜ.',
'😲😲',
'🌎🌎 🧔🏾‍♂️👩🏾‍🦱',
'📃📃',
'🏢🛏️🍴💵',
'⚽🧔🏾‍♂️👩🏾‍🦱',
'🏃🏾🪨',
'🥷🏾🥷🏾',
'🚲🚲',
'ማስነሳት',
'🫁😮‍💨',
'⚠️⚠️',
'🤏🏾🤏🏾',
'🪢🪢',
'🎤🎙️🕺🏾💃🏾',
'👷🏾‍♂️👷🏾‍♀️🏗️',
'🕔⏩',
'🏗️👷🏾‍♀️',
'5️⃣',
'📈⬆️',
'🏐🏏🏑🏒🏓🏸🏹🏃🏾‍♂️🥇🥈',
'🤰🏾🤰🏾',
'በሰውነት ወይም በብርሃን ፣ በሙቀት ፣ በድምፅ ሳይስብ ወደ ኋላ መወርወር።',
'👫🧑🏿‍❤️‍🧑🏾',
'🗑️🚮',
'🔭🔭',
'በሌላ ቋንቋ (ቃላት ወይም ጽሑፍ) ስሜትን መግለጽ።',
'🥋🥋',
'🟩🟧',
'💪🏾🧔🏾‍♂️',
];