import firebase from 'firebase';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCk8awaG3APHbm-rXnceX_JdMvKhrlVfn4",
  authDomain: "my-amharic-wordle.firebaseapp.com",
  databaseURL: "https://my-amharic-wordle-default-rtdb.firebaseio.com",
  projectId: "my-amharic-wordle",
  storageBucket: "my-amharic-wordle.appspot.com",
  messagingSenderId: "109117811925",
  appId: "1:109117811925:web:9aa8cce6baadc11c7f865c",
  measurementId: "G-QP40Y1N5SY"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var database = firebase.database();
  
export default database;